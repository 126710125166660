import { Component, OnInit, Input } from '@angular/core';
import { User, UserService } from 'src/app/services/user.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { Gateway, GatewayService } from 'src/app/services/gateway.service';

@Component({
  selector: 'app-user-resource-form',
  templateUrl: './user-resource-form.component.html',
  styleUrls: ['./user-resource-form.component.scss']
})
export class UserResourceFormComponent implements OnInit {
  user: User & { password2?: string };
  gateways: Gateway[];
  currentUser: User;
  errorMessage = '';
  isSelf: boolean;
  parentPathUser = ['manager'];
  parentPathAdmin = ['manager', 'admin', 'users'];

  constructor(
    public router: Router,
    public userService: UserService,
    public gatewayService: GatewayService,
    public auth: AuthService,
    public activatedRoute: ActivatedRoute
  ) { }

  async ngOnInit() {
    this.activatedRoute.data.subscribe(async data => {
      this.isSelf = data.isSelf;
      if (this.isSelf) {
        this.user = this.auth.user;
        this.setUserDefaults();
      }
    });

    this.activatedRoute.params.subscribe(async params => {
      if (params.id) {
        if (params.id === 'create') {
          this.user = {
            active: true,
            business: {
              active: true,
              gateway: null,
              lists: []
            }
          };
        } else {
          this.user = await this.userService.get(params.id);
        }
      }
      this.setUserDefaults();
    });

    this.currentUser = this.auth.user;

    if (this.currentUser.role === 'admin') {
      this.gateways = (await this.gatewayService.find({
        $sort: {
          name: 1
        }
      })).data;
    }
  }

  setUserDefaults() {
    if (!this.user.business) this.user.business = {
      active: true,
      gateway: null
    };

    if (!this.user.role) this.user.role = 'user';
  }

  async submitForm (event: Event) {
    const userObj = {...this.user};

    if (this.user.password) {
      if (this.user.password !== this.user.password2) {
        this.errorMessage = 'Your passwords aren\'t identical.';
        return;
      }
    } else if (this.user._id === this.auth.user._id && this.user.needsPasswordUpdate) {
      this.errorMessage = 'Please change your password.';
      return;
    }

    delete userObj.password2;

    try {
      this.errorMessage = '';
      if (this.user._id) {
        const result = await this.userService.patch(userObj._id, userObj);
      } else {
        await this.userService.create(userObj);
      }
    } catch (e) {
      console.error('An error has occurred: ', e);
      if (e.className === 'conflict' && e.errors.email) {
         this.errorMessage = `The email ${userObj.email} already exists.`;
      } else {
        this.errorMessage = 'An error occurred. Please try again later.';
      }
    }

    if (!this.errorMessage) {
      if (this.isSelf || this.auth.user.role !== 'admin') {
        this.router.navigate(this.parentPathUser);
      } else {
        this.router.navigate(this.parentPathAdmin);
      }
    }
  }

  closeError() {
    this.errorMessage = '';
  }
}
