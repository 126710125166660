import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { LoginComponent } from './components/login/login.component';
import { ManagerComponent } from './components/manager/manager.component';
import { LoginGuard } from './guards/login.guard';
import { UserResourceComponent } from './components/manager/resources/user-resource/user-resource.component';
import { UserResourceFormComponent } from './components/manager/resources/user-resource/user-resource-form/user-resource-form.component';
import { UserDashboardComponent } from './components/manager/user-dashboard/user-dashboard.component';
import { GatewayResourceComponent } from './components/manager/resources/gateway-resource/gateway-resource.component';
import { GatewayResourceFormComponent } from './components/manager/resources/gateway-resource/gateway-resource-form/gateway-resource-form.component';
import { ListResourceComponent } from './components/manager/resources/list-resource/list-resource.component';
import { ListResourceFormComponent } from './components/manager/resources/list-resource/list-resource-form/list-resource-form.component';
import { SmsResourceComponent } from './components/manager/resources/sms-resource/sms-resource.component';
import { StatisticsResourceComponent } from './components/manager/resources/statistics-resource/statistics-resource.component';
import { AdminGuard } from './guards/admin.guard';
import { FileUploadResourceFormComponent } from './components/manager/resources/file-upload-resource/file-upload-resource-form/file-upload-resource-form.component';
import { FileUploadResourceComponent } from './components/manager/resources/file-upload-resource/file-upload-resource.component';

/*
  Our app's routes.
  If you don't know what this means, check https://angular.io/docs/ts/latest/guide/router.html
 */
const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    canActivate: [AuthGuard],
    redirectTo: '/manager'
  },
  {
    path: 'manager',
    component: ManagerComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: UserDashboardComponent
      },
      {
        path: 'admin',
        canActivate: [AdminGuard],
        children: [{
          path: 'users',
          component: UserResourceComponent
        },
        {
          path: 'users/:id',
          component: UserResourceFormComponent,
          data: {
            isSelf: false
          }
        },
        {
          path: 'gateways',
          component: GatewayResourceComponent
        },
        {
          path: 'gateways/:id',
          component: GatewayResourceFormComponent
        },

        {
          path: 'lists',
          component: ListResourceComponent
        },
        {
          path: 'lists/user/:id',
          component: ListResourceComponent
        },
        {
          path: 'smses',
          component: SmsResourceComponent
        }]
      },
      {
        path: 'user/profile',
        component: UserResourceFormComponent,
        data: {
          isSelf: true
        }
      },
      {
        path: 'admin/lists/:id',
        component: ListResourceFormComponent
      },
      {
        path: 'admin/file-upload',
        component: FileUploadResourceComponent
      },
      {
        path: 'admin/file-upload/:id',
        component: FileUploadResourceFormComponent
      },
      {
        path: 'statistics',
        component: StatisticsResourceComponent
      },
      {
        path: 'smses',
        component: SmsResourceComponent
    }
    ]
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [LoginGuard]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
