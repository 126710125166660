import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';


@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private auth: AuthService) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    return this.auth
      /* Try to auth with the server. */
      .logIn()
      /* Check if the user needs to change their password. */
      .then(() => {
        if (this.auth.user.needsPasswordUpdate && state.url !== '/manager/user/profile') {
          this.router.navigate(['/manager/user/profile'], {
            state: {
              mustChangePassword: true
            }
          });
          return false;
        } else {
          return true;
        }
      })
      .catch(err => {
        console.log('go to login');
        this.router.navigate(['/login']);
        return false;
      });
  }
}
