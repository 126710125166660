import { Injectable } from '@angular/core';
import { Feathers } from './feathers.service';
import { Observable } from 'rxjs';
import { MongoDbModel } from './MongoDbModel';
import { Params } from '@angular/router';
import { Id, NullableId } from '@feathersjs/feathers';

export interface Sms {
  _id?: string;
  sender?: string;
  receiver?: string;
  text?: string;
  status?: string;
}

@Injectable({
  providedIn: 'root'
})
export class SmsService {
  constructor(private feathers: Feathers) { }


  service = this.feathers.service('smses');

  // create observables to allow for realtime data updates
  private _patch$ = new Observable<{sms: Sms & MongoDbModel, params: Params}>(subscriber => {
    this.service.on('patched', (sms, params) => subscriber.next({sms, params}));
  });

  private _create$ = new Observable<{sms: Sms & MongoDbModel, params: Params}>(subscriber => {
    this.service.on('created', (sms, params) => subscriber.next({sms, params}));
  });

  private _remove$ = new Observable<{sms: Sms & MongoDbModel, params: Params}>(subscriber => {
    this.service.on('removed', (sms, params) => subscriber.next({sms, params}));
  });

  get patch$() {
    return this._patch$;
  }

  get create$() {
    return this._create$;
  }

  get remove$() {
    return this._remove$;
  }

  async get(id: Id, params?: Params): Promise<Sms> {
    return this.service.get(id, params);
  }

  async create(data: Sms, params?: Params): Promise<Sms> {
    return this.service.create(data, params);
  }

  async patch(id: NullableId, data: any, params?: Params): Promise<Sms> {
    return this.service.patch(id, data, params);
  }

  async remove(id: NullableId, params?: Params): Promise<Sms> {
    return this.service.remove(id, params);
  }

  async find(params?: Params) {
    return this.service.find(params);
  }

  async findAggregated(params?: Params) {
    if (!params) params = { query: {} };
    params.query.aggregate = true;
    return this.service.find(params);
  }

  async sendSms(data: { recipients: {number: string, name?: string}[], text: string, list: string, statistics: boolean}, params?: Params): Promise<Sms> {
    return this.service.create(data, params);
  }
}
