import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getById'
})
export class GetByIdPipe implements PipeTransform {

  transform(value: any, id): any {
    if (!id || !value || !value.find) return value;

    return value.find(el => el._id.toString() === id.toString());
  }
}
