import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { ResourcesComponent } from '../resources.component';
import { ActivatedRoute, Router } from '@angular/router';
import { SmsService, Sms } from 'src/app/services/sms.service';
import { AuthService } from 'src/app/services/auth.service';



@Component({
  selector: 'app-user-resource',
  templateUrl: '../resources.component.html',
  styleUrls: ['../resources.component.scss']
})
export class SmsResourceComponent extends ResourcesComponent {
  hideAdminNav: boolean;
  pageName: string;
  showDateFilter = true;
  csvDownload = true;
  editable = false;

  @ViewChild('butCol', {static: true}) butCalTemplate: TemplateRef<any>;
  @ViewChild('datetime', {static: true}) datetimeTemplate: TemplateRef<any>;

  baseFilter = {
    $sort: {
      createdAt: -1
    },
    deleted: {
      $in: [true, false, null]
    },
    hide: {
      $in: [false, null]
    }
  };
  page = {
    pageNumber: 0,
    pageLimit: 100,
    totalElements: 0,
    sort: {
      createdAt: 1
    }
  };
  currentFilterString: string;
  currentFilterFrom: number;
  currentFilterTo: number;

  constructor(
    private smsService: SmsService,
    private router: Router,
    private auth: AuthService) {
    super(smsService);
  }

  onChangePatch(el) {

    const index = this.data.findIndex(entry => entry._id === el._id);
    // tslint:disable-next-line: no-bitwise
    if (~index) {
      this.data[index] = el;
      this.data = [...this.data]; // change detection
    }
  }

  onChangeRemove(el) {
    const index = this.data.findIndex(entry => entry._id === el.sms._id);
    // tslint:disable-next-line: no-bitwise
    if (~index) {
      this.data.splice(index, 1);
      this.data = [...this.data]; // change detection
    }
    this.page.totalElements -= 1;
  }

  onChangeCreate(el) {
    const regexp = new RegExp(this.currentFilterString, 'i');
    if ((el.sms.text || '').match(regexp) || (el.sms.receiver || '').match(regexp)) {
      this.data.push(el.sms);
      this.data.sort((a, b) => {
        if (a.text < b.text) {
          return -1;
        } else if (a.text > b.text) {
          return 1;
        } else {
          if (a.receiver < b.receiver) {
            return -1;
          } else if (a.receiver > b.receiver) {
            return 1;
          } else {
            return 0;
          }
        }
      });
      if (this.data.length > this.page.pageLimit) {
        this.data = this.data.splice(0, this.page.pageLimit);
      } else {
        this.data = [...this.data]; // change-detection trigger
      }
      this.page.totalElements += 1;
    }
  }
  // tslint:disable-next-line: use-life-cycle-interface
  ngOnInit () {
    super.ngOnInit();
    this.hideAdminNav = this.auth.user.role !== 'admin';
    this.filterPlaceholder = this.auth.user.role !== 'admin' ? 'Type to filter ' : 'Type to filter the sender column...';
    this.pageName = this.auth.user.role !== 'admin' ? 'Sent SMS': null;

    this.columns = [
      {name: 'Sent At', prop: 'sentAt', sortable: false, cellTemplate: this.datetimeTemplate},
      {name: 'Campaign', prop: 'list.name', sortable: false},
      {name: 'Sender', prop: 'sender.name', sortable: false},
      {name: 'Text', prop: 'text', sortable: false},
      {name: 'Receiver', prop: 'receiver', sortable: false},
      {name: 'Status', prop: 'status', sortable: false},
      {name: 'Options', prop: '_id', sortable: false, cellTemplate: this.butCalTemplate}
    ];
    this.filterChangeObservable.subscribe(async (event) => {
      if (event.srcElement.getAttribute('type') === 'date') {
        const val = (new Date(event.srcElement.value)).valueOf();
        if (!isNaN(val)) {
          if (event.srcElement.id === 'from') {
            this.currentFilterFrom = val;
          }
          if (event.srcElement.id === 'to') {
            this.currentFilterTo = val;
          }
        } else {
          if (event.srcElement.id === 'from') {
            this.currentFilterFrom = null;
          }
          if (event.srcElement.id === 'to') {
            this.currentFilterTo = null;
          }
        }
      }

      if (event.srcElement.getAttribute('type') === 'text') {
        this.currentFilterString = event.srcElement.value.trim();
      }

      const newFilters: any = {};

      if (this.currentFilterString) {
        newFilters['$or'] = [
          {sender: this.currentFilterString },
          {name: this.currentFilterString }
        ];
      }

      if (this.currentFilterFrom) {
        newFilters.dateFrom = this.currentFilterFrom;
      }

      if (this.currentFilterTo) {
        newFilters.dateTo = this.currentFilterTo + 23 * 3600 * 1000;
      }

      this.currentFilter = newFilters;

      await this.setPage({ offset: 0 });
    });
  }

  openEdit (event, id: String) {
    this.router.navigate(['manager', 'admin', 'smses', id]);
  }

  openCreate (event) {
    this.router.navigate(['manager', 'admin', 'smses', 'create']);
  }

  async deleteEntry (event, id) {
    const confirmation = confirm('Do you want to delete this entry?');
    if (confirmation) {
      this.smsService.remove(id);
    }
  }
}
