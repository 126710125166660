import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { faPaperPlane, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth.service';
import { List } from 'src/app/services/list.service';
import { SmsService } from 'src/app/services/sms.service';
import { GetByIdPipe } from 'src/app/utils/get-by-id.pipe';

@Component({
  selector: 'app-send-sms',
  templateUrl: './send-sms.component.html',
  styleUrls: ['./send-sms.component.scss']
})
export class SendSmsComponent implements OnInit {
  send = faPaperPlane;
  spinner = faSpinner;

  @ViewChild('sendSmsModal', {static: true}) sendSmsModal: TemplateRef<any>;
  statistics = true;
  extraNumbers: string;
  smsList: string;
  smsText: string;
  saving = false;
  errorMessage?: string;
  @Input() listData: List[];

  constructor(
    private smsService: SmsService,
    private auth: AuthService,
    private modalService: NgbModal) { }

  ngOnInit() {
  }

  openNew (id) {
    this.smsList = id || null;
    this.modalService.open(this.sendSmsModal);
  }

  openResend (resendData) {
    if (resendData.list) {
      this.smsList = resendData.list._id;
    }

    this.smsText = resendData.template;

    this.modalService.open(this.sendSmsModal);
  }

  async sendSms (modal) {
    try {
      this.errorMessage = undefined;

      const extraNumbersFormatted = this.extraNumbers ? this.extraNumbers.
        split(/\n/).
        map(el => el.trim()).
        filter(el => !!el).
        map(el => {
          const obj = el.replace(/"(.*?)"/g, (_, s) => s.replace(/,/g, '※')).split(',').map(s => s.replace(/※/g, ','));
          return {
            number: obj[0],
            name: obj[1]
          };
        }) : [];

      const smsListObj = GetByIdPipe.prototype.transform(this.listData, this.smsList);

      try {
        this.saving = true;
        await this.smsService.sendSms({
          recipients: extraNumbersFormatted.concat(smsListObj && smsListObj.numbers || []),
          text: this.smsText,
          list: this.smsList,
          statistics: this.statistics
        });
        modal.close();
      } finally {
        this.saving = false;
      }
    } catch (e) {
      console.error(e);
      this.errorMessage = String(e);
    }
  }
}
