import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { faEdit, faTrash, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { Subject, Observable, interval, Subscription } from 'rxjs';
import { debounce } from 'rxjs/operators';
import { TableColumn } from '@swimlane/ngx-datatable';
const deepcopy = require('deepcopy');


/* @Component({
  selector: 'app-resources',
  templateUrl: './resources.component.html',
  styleUrls: ['./resources.component.scss']
}) */
export class ResourcesComponent implements OnInit, OnDestroy, AfterViewInit {

  editable = true;
  creatable = true;
  csvDownload = false;
  waitingForCsv = false;
  csvPercentage = 0;
  jumpTargets: {
    resource: string;
    reference: string;
    icon: IconDefinition;
  }[] = [];
  jumpTargetActive = '';
  edit = faEdit;
  trash = faTrash;
  filterPlaceholder = '';
  showDateFilter = false;
  data = [];
  columns: TableColumn[];
  page = {
    pageNumber: 0,
    totalElements: 0,
    pageLimit: 20,
    sort: {
      createdAt: 1
    } as Object
  };
  loading = false;
  _currentFilter = {};
  baseFilter = {};
  filterChangeSubject: Subject<any>;
  filterChangeObservable: Observable<any>;
  private create: Subscription;
  private patch: Subscription;
  private remove: Subscription;

  constructor(private service) { }

  async ngAfterViewInit(): Promise<void> {
    if (this.service) {

      const query = Object.assign({}, {$limit: this.page.pageLimit, $skip: this.page.pageLimit * (this.page.pageNumber), $sort: this.page.sort}, this.currentFilter);

      const result = await this.service.find({query: query });
      this.page.totalElements = result.total;
      this.data = result.data;

      this.create = this.service.create$.subscribe(this.onChangeCreate.bind(this));
      this.patch = this.service.patch$.subscribe(this.onChangePatch.bind(this));
      this.remove = this.service.remove$.subscribe(this.onChangeRemove.bind(this));
    }
  }

  get currentFilter () {
    return Object.assign({}, this._currentFilter, this.baseFilter);
  }
  set currentFilter (value: any) {
    this._currentFilter = value;
  }

  ngOnDestroy() {
    if (this.create) this.create.unsubscribe();
    if (this.patch) this.patch.unsubscribe();
    if (this.remove) this.remove.unsubscribe();
  }

  onChangePatch(el) {
    throw new Error('Not implemented!');
  }

  onChangeRemove(el) {
    throw new Error('Not implemented!');
  }

  onChangeCreate(el) {
    throw new Error('Not implemented!');
  }

  ngOnInit() {
    this.filterChangeSubject = new Subject();
    this.filterChangeObservable = this.filterChangeSubject.pipe(debounce(() => interval(300)));
  }

  openEdit (event: MouseEvent, id: string) {
    throw new Error('not implemented');
  }

  openCreate (event: MouseEvent) {
    throw new Error('not implemented');
  }

  async setPage (event) {
    this.loading = true;
    this.page.pageNumber = event.offset;
    const query = Object.assign({}, {$limit: this.page.pageLimit, $skip: this.page.pageLimit * (this.page.pageNumber), $sort: this.page.sort}, this.currentFilter);
    const result = await this.service.find({query: query });
    this.page.totalElements = result.total;
    this.data = result.data;
    this.loading = false;
  }

  updateFilter(event) {
    this.filterChangeSubject.next(event);
  }

  async downloadCSV(event) {
    this.waitingForCsv = true;
    const largeLimit = 1000
    const baseQuery = Object.assign({}, {$limit: largeLimit, $sort: this.page.sort}, this.currentFilter);

    const result = await this.service.find({query: baseQuery});
    const totalElements = result.total;
    const pages = Math.ceil(totalElements / largeLimit);
    let data = result.data;

    if (pages > 20 && !confirm(`You are about to generate a large CSV file. This can take a while and, depending on browser or internet connection, may time out before completion. Try setting a date range filter.\nDo you want to try anyway?`)) {
      this.waitingForCsv = false;
      this.csvPercentage = 0;
      return;
    }

    for (let i = 1; i < (pages - 1); i++) {
      this.csvPercentage = i / pages;
      const pageResult = await this.service.find({query: {$skip: largeLimit * i, ...baseQuery}});
      data = [...pageResult.data];
    }

    const dataFields = this.columns.filter(col => col.name != 'Options');

    const headers =  dataFields.map(col => `"${col.name}"`)
    const csvData = data.map(row => {
      return dataFields.map(field => {
        let el = row;
        for (const pathPart of (field.prop as string).split('.')) {
          if (!el) break; // optional elements
          el = el[pathPart];
        }
        el = el || null; // normalize to null

        if (field.cellTemplate && field.cellTemplate._def.references.datetime) { // dig into the depths of angular to check if the template we're using is the datetime template  
          el = new Date(el).toLocaleString( 'au', { timeZoneName: 'short' } );
        }

        return el;
      }).map(el => `"${(el || '').replace(/"/g, '""').replace(/\n/g, ' ')}"`).join(',');
    })

    this.waitingForCsv = false;
    this.csvPercentage = 0;

    const csv = headers + '\n' + csvData.join('\n');
    const blob = new Blob([csv], { type: 'text/csv'});

    const a = document.createElement('a');
    a.href = URL.createObjectURL(blob);
    a.download = 'sent_sms.csv';

    a.click();
  }
}
