import { Component, OnInit, Input } from '@angular/core';
import { List, ListService } from 'src/app/services/list.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-list-resource-form',
  templateUrl: './list-resource-form.component.html',
  styleUrls: ['./list-resource-form.component.scss']
})
export class ListResourceFormComponent implements OnInit {
  list: List;
  listStr = '';
  errorMessage = '';
  parentPathUser = ['manager'];
  parentPathAdmin = ['manager', 'admin', 'lists'];

  constructor(
    public router: Router,
    public listService: ListService,
    public auth: AuthService,
    public activatedRoute: ActivatedRoute
  ) { }

  setList (list: List = this.list) {
    if (this.list !== list) this.list = list;
    this.listStr = list.numbers
      .map(({ number, name }) => [number, ...name ? [name.includes(',') ? `"${name.replace(/"/g, '')}"` : name] : []].join(','))
      .join('\n')
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe(async params => {
      if (params.id && params.id != 'create') {
        this.setList(await this.listService.get(params.id));
      } else {
        this.setList({ numbers: [] });
      }
    });
  }

  async submitForm (event: Event) {
    try {
      const numbersList = this.listStr.
        split('\n').
        map((el: string) => el.replace(/"(.*?)"/g, (_, s) => s.replace(/,/g, '※')).split(',').map(s => s.replace(/※/g, ','))).
        map(numberData => {
          return {
            number: numberData[0].replace(/^\+\D*6\D*1/, '0').replace(/\D+/g, ''),
            name: numberData[1]
          }
        });
      this.list.numbers = numbersList;
      this.setList();

      if (!this.list.user) this.list.user = this.auth.user._id;
      this.errorMessage = '';
      if (this.list._id) {
        const result = await this.listService.patch(this.list._id, this.list);
      } else {
        await this.listService.create(this.list);
      }
      this.router.navigate(this.auth.user.role === 'admin' ? this.parentPathAdmin : this.parentPathUser);
    } catch (e) {
      console.error('An error has occurred: ', e);
      this.errorMessage = 'An error occurred. Please try again later.'
    }
  }

  closeError() {
    this.errorMessage = '';
  }
}
