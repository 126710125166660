import { Feathers } from './feathers.service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { User, UserService } from './user.service';
import { MongoDbModel } from './MongoDbModel';
import { Subscription } from 'rxjs';

/**
 * Abstraction layer for auth. Nice to have when things get more complicated.
 */
@Injectable()
export class AuthService {
  private _user: User & MongoDbModel;

  constructor(private feathers: Feathers, private router: Router, private userService: UserService) {
    userService.patch$.subscribe(({user}) => {
      if (this.user._id === user._id && this.user.business.active !== user.business.active) {
        return window.location.reload();
      }
      if (this._user._id === user._id) this._user = user;
    });

    userService.remove$.subscribe(({user}) => {
      if (this._user._id === user._id) this.logOut();
    });

  }

  get user () {
    return this._user;
  }

  public async logIn(credentials?): Promise<User & MongoDbModel> {
      const result = await this.feathers.authenticate(credentials);
      this._user = result.user;
      console.log('logged in');
      return result;
  }

  public logOut() {
    this.feathers.logout().then(() => {
      delete this._user;
      return this.router.navigate(['/login']);
    });
  }
}
