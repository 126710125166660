import { Component, OnInit, Input } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { faUser, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';

@Component({
  selector: 'app-top-navbar',
  templateUrl: './top-navbar.component.html',
  styleUrls: ['./top-navbar.component.scss']
})
export class TopNavbarComponent implements OnInit {
  user = faUser;
  signOut = faSignOutAlt;

  constructor(public auth: AuthService, public router: Router) { }

  ngOnInit() {
  }

  public logout () {
    this.auth.logOut();
  }
}
