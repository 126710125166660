import { Component, HostBinding, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { FileManagerService } from 'src/app/services/file-manager.service';
import { environment } from 'src/environments/environment';
import { ResourcesComponent } from '../resources.component';

@Component({
  selector: 'app-file-upload-resource',
  templateUrl: '../resources.component.html',
  styleUrls: ['../resources.component.scss', './file-upload-resource.component.scss']
})
export class FileUploadResourceComponent extends ResourcesComponent implements OnInit {
  @ViewChild('butCol', {static: true}) butCalTemplate: TemplateRef<any>;
  @ViewChild('file', {static: true}) fileTemplate: TemplateRef<any>;
  @ViewChild('link', {static: true}) linkTemplate: TemplateRef<any>;
  @ViewChild('date', {static: true}) dateTemplate: TemplateRef<any>;
  @HostBinding('class.admin') get valid() { return this.auth.user.role !== 'admin'; }
  filterPlaceholder = 'Type to filter the name column...';

  currentFilterString: string;
  editable = true;
  hideAdminNav: boolean;
  fileHost = environment.fileHostUrl;
  pageName = 'Upload Manager';
  new = 'New Upload';
  timestamp = Date.now();
  disableNew = this.auth.user.role === 'admin';


  constructor(
    private fileMangerService: FileManagerService,
    private router: Router,
    private auth: AuthService,
    private sanitizer: DomSanitizer) {
    super(fileMangerService);
  }

  public getSantizeUrl(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  onChangeRemove(el) {
    const index = this.data.findIndex(entry => entry._id === el.fileData._id);
    // tslint:disable-next-line: no-bitwise
    if (~index) {
      this.data.splice(index, 1);
      this.data = [...this.data]; // change detection
    }
    this.page.totalElements -= 1;
  }

  // tslint:disable-next-line: use-life-cycle-interface
  ngOnInit () {
    super.ngOnInit();
    this.hideAdminNav = this.auth.user.role !== 'admin';
    this.columns = [
      {name: 'Created', prop: 'createdAt', comparator: (valueA, valueB, rowA, rowB, sortDirection) => new Date(valueA).valueOf() - new Date(valueB).valueOf(), sortable: false, cellTemplate: this.dateTemplate},
      {name: 'Name', prop: 'name', sortable: false},
      {name: 'File', prop: 'fileName', sortable: false, cellTemplate: this.fileTemplate},
      {name: 'URL', prop: 'fileName', sortable: false, minWidth: 250, cellTemplate: this.linkTemplate},
      {name: 'Options', prop: '_id', sortable: false, cellTemplate: this.butCalTemplate}
    ];
    if (this.auth.user.role === 'admin') {
      this.columns.splice(1, 0, {name: 'User', prop: 'user.name', sortable: false});
    }

    this.filterChangeObservable.subscribe(async (event) => {
      if (event.srcElement.value.trim().length) {
        this.currentFilterString = event.srcElement.value.trim();
        this.currentFilter = {name: {$search: this.currentFilterString}};
      } else {
        this.currentFilter = {};
      }
      await this.setPage({ offset: 0 });
    });
  }

  openEdit (event, id: String) {
    this.router.navigate(['manager', 'admin', 'file-upload', id]);
  }

  openCreate (event) {
    this.router.navigate(['manager', 'admin', 'file-upload', 'create']);
  }

  async deleteEntry (event, id) {
    const confirmation = confirm('Do you want to delete this entry?');
    if (confirmation) {
      this.fileMangerService.remove(id);
    }
  }
}
