import { Injectable } from '@angular/core';
import { Feathers } from './feathers.service';
import { Observable } from 'rxjs';
import { MongoDbModel } from './MongoDbModel';
import { Params } from '@angular/router';
import { Id, NullableId } from '@feathersjs/feathers';

export interface List {
  _id?: string;
  user?: string;
  name?: string;
  numbers: {
    number: string;
    name: string;
  }[];
}

@Injectable({
  providedIn: 'root'
})
export class ListService {
  constructor(private feathers: Feathers) { }


  service = this.feathers.service('lists');

  // create observables to allow for realtime data updates
  private _patch$ = new Observable<{list: List & MongoDbModel, params: Params}>(subscriber => {
    this.service.on('patched', (list, params) => subscriber.next({list, params}));
  });

  private _create$ = new Observable<{list: List & MongoDbModel, params: Params}>(subscriber => {
    this.service.on('created', (list, params) => subscriber.next({list, params}));
  });

  private _remove$ = new Observable<{list: List & MongoDbModel, params: Params}>(subscriber => {
    this.service.on('removed', (list, params) => subscriber.next({list, params}));
  });

  get patch$() {
    return this._patch$;
  }

  get create$() {
    return this._create$;
  }

  get remove$() {
    return this._remove$;
  }

  async get(id: Id, params?: Params): Promise<List> {
    return this.service.get(id, params);
  }

  async create(data: List, params?: Params): Promise<List> {
    return this.service.create(data, params);
  }

  async patch(id: NullableId, data: any, params?: Params): Promise<List> {
    return this.service.patch(id, data, params);
  }

  async remove(id: NullableId, params?: Params): Promise<List> {
    return this.service.remove(id, params);
  }

  async find(params?: Params) {
    const res = await this.service.find(params);
    return res;
  }
}
