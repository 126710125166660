import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { ManagerComponent } from './components/manager/manager.component';
import { AuthService } from './services/auth.service';
import { AuthGuard } from './guards/auth.guard';
import { TopNavbarComponent } from './components/manager/top-navbar/top-navbar.component';
import { SideNavComponent } from './components/manager/side-nav/side-nav.component';
import { UserResourceComponent } from './components/manager/resources/user-resource/user-resource.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { UserResourceFormComponent } from './components/manager/resources/user-resource/user-resource-form/user-resource-form.component';
import { NgbModalModule, NgbPaginationModule, NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { UserDashboardComponent } from './components/manager/user-dashboard/user-dashboard.component';
import { GatewayResourceComponent } from './components/manager/resources/gateway-resource/gateway-resource.component';
import { GatewayResourceFormComponent } from './components/manager/resources/gateway-resource/gateway-resource-form/gateway-resource-form.component';
import { ListResourceComponent } from './components/manager/resources/list-resource/list-resource.component';
import { ListResourceFormComponent } from './components/manager/resources/list-resource/list-resource-form/list-resource-form.component';
import { SmsResourceComponent } from './components/manager/resources/sms-resource/sms-resource.component';
import { GetByIdPipe } from './utils/get-by-id.pipe';
import { StatisticsResourceComponent } from './components/manager/resources/statistics-resource/statistics-resource.component';
import { SendSmsComponent } from './components/manager/send-sms/send-sms.component';
import { ResponseStatisticsComponent } from './components/manager/resources/statistics-resource/response-statistics-modal/response-statistics-modal.component';
import { FileUploadResourceComponent } from './components/manager/resources/file-upload-resource/file-upload-resource.component';
import { FileUploadResourceFormComponent } from './components/manager/resources/file-upload-resource/file-upload-resource-form/file-upload-resource-form.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ManagerComponent,
    TopNavbarComponent,
    SideNavComponent,
    UserResourceComponent,
    UserResourceFormComponent,
    GatewayResourceComponent,
    GatewayResourceFormComponent,
    SmsResourceComponent,
    ListResourceComponent,
    ListResourceFormComponent,
    UserDashboardComponent,
    GetByIdPipe,
    StatisticsResourceComponent,
    SendSmsComponent,
    ResponseStatisticsComponent,
    FileUploadResourceComponent,
    FileUploadResourceFormComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    FontAwesomeModule,
    NgbModalModule,
    NgbPaginationModule,
    NgbAlertModule,
    NgSelectModule,
    NgxDatatableModule
  ],
  providers: [
    AuthService,
    AuthGuard,
    { provide: LOCALE_ID, useValue: 'en-AU'}
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    UserResourceFormComponent
  ]
})
export class AppModule { }
