import { AfterViewInit, Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgModel } from '@angular/forms';
import { Router } from '@angular/router';
import { faAddressBook, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TableColumn } from '@swimlane/ngx-datatable';
import { interval, Observable, Subject, Subscription } from 'rxjs';
import { debounce } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { List, ListService } from 'src/app/services/list.service';
import { SmsService } from 'src/app/services/sms.service';
import { User, UserService } from 'src/app/services/user.service';
import { SendSmsComponent } from '../../send-sms/send-sms.component';
import { faClipboardList } from '@fortawesome/free-solid-svg-icons';
import { Paginated } from '@feathersjs/feathers';
import { ResponseStatisticsComponent } from './response-statistics-modal/response-statistics-modal.component';
import pLimit from 'p-limit';

@Component({
  selector: 'app-statistics-resource',
  templateUrl: './statistics-resource.component.html',
  styleUrls: ['./statistics-resource.component.scss']
})
export class StatisticsResourceComponent implements OnInit, AfterViewInit, OnDestroy {
  send = faPaperPlane;
  clipboardList = faClipboardList;
  addressBook = faAddressBook;
  users: User[];
  lists: List[];
  listsById: {[key: string]: List};
  listsByName: {[key: string]: string[]}[];
  data: any[];
  columns: TableColumn[];
  page = {
    pageNumber: 0,
    totalElements: 0,
    pageLimit: 20,
  };
  _currentFilter = {};
  baseFilter = {};
  filterChangeSubject: Subject<any>;
  filterChangeObservable: Observable<any>;
  errorMessage?: string;
  limit = pLimit(10);
  private create: Subscription;
  private patch: Subscription;
  private remove: Subscription;

  @ViewChild('butCol', {static: true}) butCalTemplate: TemplateRef<any>;
  @ViewChild('datetime', {static: true}) datetimeTemplate: TemplateRef<any>;
  @ViewChild('failed', {static: true}) failedTemplate: TemplateRef<any>;
  @ViewChild('center', {static: true}) centerTemplate: TemplateRef<any>;
  @ViewChild(SendSmsComponent, {static: true}) smsModal: SendSmsComponent;
  @ViewChild(ResponseStatisticsComponent, {static: true}) responseModal: ResponseStatisticsComponent;
  @ViewChild('userField', {static: false}) userField: NgModel;
  @ViewChild('failedNumbersModal', {static: true}) failedNumbersModal: TemplateRef<any>;

  filterPlaceholder = 'Type to filter the List Name...';
  sorts = [
    {
      prop: 'timestamp',
      dir: 'desc'
    },
  ];
  currentFilterString: string;
  failedNumbers: string[];
  loading: boolean;

  constructor(
    private smsService: SmsService,
    private userService: UserService,
    private listService: ListService,
    private router: Router,
    private auth: AuthService,
    private modalService: NgbModal) {
  }

  async ngOnInit () {
    this.users = (await this.userService.find({})).data.filter(user => user.role !== 'admin');

    this.filterChangeSubject = new Subject();
    this.filterChangeObservable = this.filterChangeSubject.pipe(debounce(() => interval(300)));

    this.columns = [
      {name: 'Date/Time', prop: 'timestamp', sortable: false, maxWidth: 150, cellTemplate: this.datetimeTemplate},
      {name: 'Campaign', prop: 'list.name', sortable: false},
      {name: 'SMS Content', minWidth: 250, prop: 'template', sortable: false},
      {name: 'Count', prop: 'count', sortable: false, maxWidth: 100, cellTemplate: this.centerTemplate},
      {name: 'Successful', prop: 'successful', maxWidth: 100, sortable: false, cellTemplate: this.centerTemplate},
      {name: 'Failed', prop: 'failedNumbers', sortable: false, maxWidth: 100, cellTemplate: this.failedTemplate},
      {name: 'Options', sortable: false, minWidth: 200, maxWidth: 250, cellTemplate: this.butCalTemplate}
    ];

    if (this.auth.user.role === 'admin') {
      this.columns.splice(1, 0, {name: 'Sender', prop: 'sender.name', sortable: false});
    } else {
      if (!this.data) this.loading = true;
    }

    this.filterChangeObservable.subscribe(async (event) => {
      if (event.srcElement.value.trim().length) {
        this.currentFilterString = event.srcElement.value.trim();
        this.currentFilter = {
          name: {
            $search: this.currentFilterString
          }
        };
      } else {
        this.currentFilter = {};
      }

      const user = this.auth.user.role !== 'admin' ? this.auth.user._id : this.userField.value;

      if (user) this.fetchStatistics(user);

    });
  }

  async ngAfterViewInit(): Promise<void> {
    await this.updateIdList();

    if (this.auth.user.role !== 'admin') {
      await this.fetchStatistics(this.auth.user._id);
    }

    this.create = this.smsService.create$.subscribe(this.onChangeCreate.bind(this));
    this.patch = this.smsService.patch$.subscribe(this.onChangePatch.bind(this));
    this.remove = this.smsService.remove$.subscribe(this.onChangeRemove.bind(this));
  }

  get currentFilter () {
    return Object.assign({}, this._currentFilter, this.baseFilter);
  }
  set currentFilter (value: any) {
    this._currentFilter = value;
  }

  ngOnDestroy() {
    if (this.create) this.create.unsubscribe();
    if (this.patch) this.patch.unsubscribe();
    if (this.remove) this.remove.unsubscribe();
  }

  onChangePatch(el) {
    const user = this.auth.user.role !== 'admin' ? this.auth.user._id : this.userField.value;

    if (user) this.fetchStatistics(user);
  }

  onChangeRemove(el) {
    const user = this.auth.user.role !== 'admin' ? this.auth.user._id : this.userField.value;

    if (user) this.fetchStatistics(user);
}

  onChangeCreate(el) {
    const user = this.auth.user.role !== 'admin' ? this.auth.user._id : this.userField.value;

    if (user) this.fetchStatistics(user);
}

  updateFilter(event) {
    this.filterChangeSubject.next(event);
  }

  async fetchStatistics(userId) {
    if (!userId) return;

    try {
      this.errorMessage = undefined;
      this.loading = true;

      const query = Object.assign({
        sender: userId,
        statistics: {
          $in: [true, null]
        },
        $limit: 1000
      }, this.currentFilter);

      const result = await this.smsService.findAggregated({
        query
      });

      this.data = result;

      this.data = result.map(el => {
        el.list = this.listsById[el.list] || { _id: null, name: 'No list'};

        return el;
      });

      this.page.totalElements = result.length;
    } catch (e) {
      this.errorMessage = String(e);
      console.error(e);
    } finally {
      this.loading = false;
    }
  }

  async updateIdList(userId?) {
    this.lists = (await this.listService.find({
      user: userId
    })).data;

    this.listsByName = [];
    this.listsById = {};

    for (const list of this.lists) {
      const listName = list.name ? list.name.toLowerCase() : 'N/A';

      if (!this.listsByName[listName]) this.listsByName[listName] = [];

      this.listsByName[listName].push(list._id);
      this.listsById[list._id] = list;
    }
  }

  openResend (event, id: String) {
    const resendData = this.data.find(el => (!el.sendId && !id) || el.sendId === id);
    if (resendData) this.smsModal.openResend(resendData);
  }

  openResponses (event, id: String) {
    this.responseModal.open(id);
  }

  aggregateData(data) {
    const aggregatedData = {};

    for (const sms of data) {
      if (!aggregatedData[sms.sendId]) {
        aggregatedData[sms.sendId] = {
          sendId: sms.sendId,
          timestamp: new Date(sms.createdAt),
          sender: sms.sender,
          list: sms.list || { _id: null, name: 'No List' },
          template: sms.template || 'N/A',
          count: 0,
          successful: 0,
          failed: 0,
          failedNumbers: []
        };
      }

      aggregatedData[sms.sendId].count++;

      if (sms.status === 'success') aggregatedData[sms.sendId].successful++;
      if (sms.status === 'error')  {
        aggregatedData[sms.sendId].failed++;
        aggregatedData[sms.sendId].failedNumbers.push(sms.receiver);
      }
    }

    return Object.values(aggregatedData).sort((a: any, b: any) => b.timestamp - a.timestamp);
  }


  showFailedNumbersModal (failedNumbers) {
    if (!failedNumbers) return;
    const failedNumbersModal = this.modalService.open(this.failedNumbersModal);
    this.failedNumbers = failedNumbers.join('\n');
  }
}
