import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './services/auth.service';
import { UserService } from './services/user.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  constructor(private userService: UserService, private auth: AuthService, private router: Router) {}

  ngOnInit(): void {
    this.userService.patch$.subscribe(({user}) => {
      if (user._id === this.auth.user._id && !user.active) {
        this.auth.logOut();
        this.router.navigateByUrl('/login');
      }
    });
  }
}
