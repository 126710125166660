import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { ResourcesComponent } from '../resources.component';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService, User } from 'src/app/services/user.service';
import { AuthService } from 'src/app/services/auth.service';
import { faList } from '@fortawesome/free-solid-svg-icons';



@Component({
  selector: 'app-user-resource',
  templateUrl: '../resources.component.html',
  styleUrls: ['../resources.component.scss']
})
export class UserResourceComponent extends ResourcesComponent {
  @ViewChild('butCol', {static: true}) butCalTemplate: TemplateRef<any>;
  jumpTargets = [{
    resource: 'lists',
    reference: 'user',
    icon: faList
  }];
  filterPlaceholder = 'Type to filter the name or email column...';
  sorts = [
    {
      prop: 'name',
      dir: 'asc'
    },
    {
      prop: 'email',
      dir: 'asc'
    }
  ];
  currentFilterString: string;

  constructor(
    private userService: UserService,
    private router: Router,
    private auth: AuthService) {
    super(userService);
  }

  onChangePatch(el) {

    const index = this.data.findIndex(entry => entry._id === el.user._id);
    // tslint:disable-next-line: no-bitwise
    if (~index) {
      this.data[index] = el.user;
      this.data = [...this.data]; // change detection
    }
  }

  onChangeRemove(el) {
    const index = this.data.findIndex(entry => entry._id === el.user._id);
    // tslint:disable-next-line: no-bitwise
    if (~index) {
      this.data.splice(index, 1);
      this.data = [...this.data]; // change detection
    }
    this.page.totalElements -= 1;
  }

  onChangeCreate(el) {
    const regexp = new RegExp(this.currentFilterString, 'i');
    if ((el.user.name || '').match(regexp) || (el.user.email || '').match(regexp)) {
      this.data.push(el.user);
      this.data.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        } else if (a.name > b.name) {
          return 1;
        } else {
          if (a.email < b.email) {
            return -1;
          } else if (a.email > b.email) {
            return 1;
          } else {
            return 0;
          }
        }
      });
      if (this.data.length > this.page.pageLimit) {
        this.data = this.data.splice(0, this.page.pageLimit);
      } else {
        this.data = [...this.data]; // change-detection trigger
      }
      this.page.totalElements += 1;
    }
  }
  // tslint:disable-next-line: use-life-cycle-interface
  ngOnInit () {
    super.ngOnInit();
    this.page.sort = { name: 1 }
    this.columns = [
      {name: 'Name', prop: 'name', sortable: false},
      {name: 'Role', prop: 'role' , sortable: false},
      {name: 'Email', prop: 'email', sortable: false},
      {name: 'Options', prop: '_id', sortable: false, cellTemplate: this.butCalTemplate}
    ];
    this.filterChangeObservable.subscribe(async (event) => {
      if (event.srcElement.value.trim().length) {
        this.currentFilterString = event.srcElement.value.trim();
        this.currentFilter = {$or: [
          {name: {$search: this.currentFilterString }},
          {email: {$search: this.currentFilterString }}
        ]};
      } else {
        this.currentFilter = {};
      }
      await this.setPage({ offset: 0 });
    });
  }

  openEdit (event, id: String) {
    this.router.navigate(['manager', 'admin', 'users', id]);
  }

  openCreate (event) {
    this.router.navigate(['manager', 'admin', 'users', 'create']);
  }

  async deleteEntry (event, id) {
    const confirmation = confirm('Do you want to delete this entry?');
    if (confirmation) {
      this.userService.remove(id);
    }
  }
}
