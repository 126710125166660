import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { Id, NullableId } from '@feathersjs/feathers';
import { Observable } from 'rxjs';
import { Feathers } from './feathers.service';
import { MongoDbModel } from './MongoDbModel';

export interface FileData {
  _id?: string;
  user?: string;
  name?: string;
  file?: string;
}

@Injectable({
  providedIn: 'root'
})
export class FileManagerService {
  constructor(private feathers: Feathers) { }


  service = this.feathers.service('img-manager');

  // create observables to allow for realtime data updates
  private _patch$ = new Observable<{fileData: FileData & MongoDbModel, params: Params}>(subscriber => {
    this.service.on('patched', (fileData, params) => subscriber.next({fileData, params}));
  });

  private _create$ = new Observable<{fileData: FileData & MongoDbModel, params: Params}>(subscriber => {
    this.service.on('created', (fileData, params) => subscriber.next({fileData, params}));
  });

  private _remove$ = new Observable<{fileData: FileData & MongoDbModel, params: Params}>(subscriber => {
    this.service.on('removed', (fileData, params) => subscriber.next({fileData, params}));
  });

  get patch$() {
    return this._patch$;
  }

  get create$() {
    return this._create$;
  }

  get remove$() {
    return this._remove$;
  }

  async get(id: Id, params?: Params): Promise<FileData> {
    return this.service.get(id, params);
  }

  async create(data: FileData, params?: Params): Promise<FileData> {
    return this.service.create(data, params);
  }

  async patch(id: NullableId, data: any, params?: Params): Promise<FileData> {
    return this.service.patch(id, data, params);
  }

  async remove(id: NullableId, params?: Params): Promise<FileData> {
    return this.service.remove(id, params);
  }

  async find(params?: Params) {
    const aaa = await this.service.find(params);
    console.log(aaa, params);
    return aaa;
  }
}
