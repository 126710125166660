import { Injectable, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Service, Params, NullableId, Id } from '@feathersjs/feathers';
import { Feathers } from './feathers.service';
import { MongoDbModel } from './MongoDbModel';
import { AuthService } from './auth.service';

export interface User {
  _id?: string;
  email?: string;
  active?: boolean;
  password?: string;
  name?: string;
  role?: string;
  needsPasswordUpdate?: boolean;
  business?: {
    active: boolean;
    gateway: string;
    sentSms?: number;
    maxSms?: number;
    lists?: string[];
    blacklist?: string[];
  }
}
@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(private feathers: Feathers) { }

  service = this.feathers.service('users');

  // create observables to allow for realtime data updates
  private _patch$ = new Observable<{user: User & MongoDbModel, params: Params}>(subscriber => {
    this.service.on('patched', (user, params) => subscriber.next({user, params}));
  });


  private _create$ = new Observable<{user: User & MongoDbModel, params: Params}>(subscriber => {
    this.service.on('created', (user, params) => subscriber.next({user, params}));
  });

  private _remove$ = new Observable<{user: User & MongoDbModel, params: Params}>(subscriber => {
    this.service.on('removed', (user, params) => subscriber.next({user, params}));
  });


  get patch$() {
    return this._patch$;
  }

  get create$() {
    return this._create$;
  }

  get remove$() {
    return this._remove$;
  }

  async get(id: Id, params?: Params): Promise<User> {
    return this.service.get(id, params);
  }

  async create(data: User, params?: Params): Promise<User> {
    return this.service.create(data, params);
  }

  async patch(id: NullableId, data: any, params?: Params): Promise<User> {
    return this.service.patch(id, data, params);
  }

  async remove(id: NullableId, params?: Params): Promise<User> {
    return this.service.remove(id, params);
  }

  async find(params?: Params) {
    return this.service.find(params);
  }
}
