import { Component, OnInit, Input } from '@angular/core';
import { Gateway, GatewayService } from 'src/app/services/gateway.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-gateway-resource-form',
  templateUrl: './gateway-resource-form.component.html',
  styleUrls: ['./gateway-resource-form.component.scss']
})
export class GatewayResourceFormComponent implements OnInit {
  gateway: Gateway;
  errorMessage = '';



  constructor(
    public router: Router,
    public gatewayService: GatewayService,
    public auth: AuthService,
    public activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe(async params => {
      if (params.id && params.id != 'create') {
        this.gateway = await this.gatewayService.get(params.id);
      } else {
        this.gateway = {};
      }
    });
  }

  async submitForm (event: Event) {
    try {
      if (this.gateway._id) {
        const result = await this.gatewayService.patch(this.gateway._id, this.gateway);
      } else {
        await this.gatewayService.create(this.gateway);
      }
    } catch (e) {
      console.error('An error has occurred: ', e);
      this.errorMessage = 'An error occurred. Please try again later.'
    }
    this.router.navigate(['manager', 'admin', 'gateways']);
  }

  closeError() {
    this.errorMessage = '';
  }
}
