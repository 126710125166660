import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Paginated } from '@feathersjs/feathers';
import { faClipboardList } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DatatableComponent, TableColumn } from '@swimlane/ngx-datatable';
import { SmsResponseService } from 'src/app/services/sms-response.service';

@Component({
  selector: 'app-response-statistics-modal',
  templateUrl: './response-statistics-modal.component.html',
  styleUrls: ['./response-statistics-modal.component.scss']
})
export class ResponseStatisticsComponent implements OnInit {
  @ViewChild('datetime', { static: true }) datetimeTemplate: TemplateRef<any>;
  @ViewChild('options', { static: true }) optionsTemplate: TemplateRef<any>;
  @ViewChild('responseStatistics', { static: true }) responseStatistics: TemplateRef<any>;
  @ViewChild('respondersModal', {static: true}) respondersModal: TemplateRef<any>;

  clipboardList = faClipboardList;

  aggregatedView = true;
  columns: TableColumn[];
  aggregatedColumns: TableColumn[];

  sendId: string;

  data: any[];
  aggregatedData: any[];
  page = {
    pageNumber: 0,
    totalElements: 0,
    pageLimit: 50,
    sort: {
      createdAt: 1
    }
  };
  currentResponses: string;

  constructor(
    private smsResponseService: SmsResponseService,
    private modalService: NgbModal
  ) { }

  ngOnInit() {
    this.columns = [
      {name: 'Date/Time', prop: 'createdAt', sortable: false, cellTemplate: this.datetimeTemplate},
      {name: 'Name', prop: 'name', sortable: false},
      {name: 'Number', prop: 'sender', sortable: false},
      {name: 'Response', prop: 'text', sortable: false}
    ];
    this.aggregatedColumns = [
      {name: 'Response', prop: 'response', sortable: false},
      {name: 'Count', prop: 'count', sortable: false},
      {name: 'Options', prop: 'numbers', sortable: false, cellTemplate: this.optionsTemplate}
    ];
  }

  async open(sendId) {
    this.sendId = sendId;
    const query = {
      responseForSendId: this.sendId
    };

    try {
      const result = await this.smsResponseService.find({
        query: query
      });

      let rawData = result.data;

      for (let i = 1; i * result.limit <= result.total; i++) {
        const res = await this.smsResponseService.find({query: Object.assign({}, query, {$skip: i * result.limit})}) as Paginated<any>;
        rawData = [...rawData, ...res.data];
      }

      this.data = rawData;
      this.aggregatedData = this.aggregateData(rawData);
  
      this.page.totalElements = result.total;

      this.modalService.open(this.responseStatistics, {size: 'lg'});
    } catch (e) {
      console.error(e);
    }
  }

  async openDetailsModal(numbers) {
    this.currentResponses = this.generateNumbersList(numbers);

    this.modalService.open(this.respondersModal);
  }

  aggregateData(data) {
    const aggregatedData = {};

    for (const dataPoint of data) {
      const normalizedResponse = dataPoint.text.toLowerCase().trim();
      if (!aggregatedData[normalizedResponse]) aggregatedData[normalizedResponse] = {
        response: normalizedResponse,
        numbers: [],
        count: 0
      }
      aggregatedData[normalizedResponse].numbers.push({
        name: dataPoint.name,
        number: dataPoint.sender
      });
      aggregatedData[normalizedResponse].count++;
    }
    return Object.values(aggregatedData);
  }

  generateCsv() {
    const pipe = new DatePipe('en_US');
    const csv = [];

    csv.push(['Date/Time', 'Name', 'Number', 'Response']);
    
    for (const dataPoint of this.data) {
      csv.push([
        `${pipe.transform(dataPoint.createdAt, 'd/M/yy')}, ${pipe.transform(dataPoint.createdAt, 'shortTime')}`,
        dataPoint.name,
        dataPoint.sender,
        dataPoint.text
      ]);
    }

    return this.stringifyCsv(csv);
  }

  generateNumbersList(numbers) {
    return this.stringifyCsv(numbers.map(num => ([num.number, num.name])));
  }

  stringifyCsv(csv) {
    return csv.map(row => {
      return row.map(col => JSON.stringify(col)).join(',');
    }).join('\n');
  }

  download() {
    const blobContent = new Blob([this.generateCsv()], {type: 'text/csv'});

    const aTag = document.createElement('a');
    aTag.download = `sms_responses_${this.sendId}.csv`;
    aTag.href = URL.createObjectURL(blobContent);

    aTag.click();
  }
}
