import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { ResourcesComponent } from '../resources.component';
import { ActivatedRoute, Router } from '@angular/router';
import { ListService, List } from 'src/app/services/list.service';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';
import { faList } from '@fortawesome/free-solid-svg-icons';



@Component({
  selector: 'app-user-resource',
  templateUrl: '../resources.component.html',
  styleUrls: ['../resources.component.scss']
})
export class ListResourceComponent extends ResourcesComponent {
  @ViewChild('butCol', {static: true}) butCalTemplate: TemplateRef<any>;
  @ViewChild('count', {static: true}) countTemplate: TemplateRef<any>;
  @ViewChild('date', {static: true}) dateTemplate: TemplateRef<any>;
  filterPlaceholder = 'Type to filter the user or name column...';
  currentFilterString = '';
  userFilter: string;
  userName: string;
  creatable: boolean;

  constructor(
    private listService: ListService,
    private userService: UserService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private auth: AuthService) {
    super(listService);
  }

  onChangePatch(el) {
    const index = this.data.findIndex(entry => entry._id === el.list._id);
    // tslint:disable-next-line: no-bitwise
    if (~index) {
      this.data[index] = el;
      this.data = [...this.data]; // change detection
    }
  }

  onChangeRemove(el) {
    const index = this.data.findIndex(entry => entry._id === el.list._id);
    // tslint:disable-next-line: no-bitwise
    if (~index) {
      this.data.splice(index, 1);
      this.data = [...this.data]; // change detection
    }
    this.page.totalElements -= 1;
  }

  onChangeCreate(el) {
    const regexp = new RegExp(this.currentFilterString, 'i');
    if ((el.list.name || '').match(regexp)) {
      this.data.push(el.list);
      this.data.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        } else if (a.name > b.name) {
          return 1;
        } else {
          return 0;
        }
      });
      if (this.data.length > this.page.pageLimit) {
        this.data = this.data.splice(0, this.page.pageLimit);
      } else {
        this.data = [...this.data]; // change-detection trigger
      }
      this.page.totalElements += 1;
    }
  }
  // tslint:disable-next-line: use-life-cycle-interface
  ngOnInit () {
    super.ngOnInit();
    this.columns = [
      {name: 'User', prop: 'user.name', sortable: false},
      {name: 'Name', prop: 'name', sortable: false},
      {name: 'Created', prop: 'createdAt', sortable: false, cellTemplate: this.dateTemplate},
      {name: 'Modified', prop: 'updatedAt', sortable: false, cellTemplate: this.dateTemplate },
      {name: 'Numbers', prop: 'numbers', sortable: false, cellTemplate: this.countTemplate},
      {name: 'Options', prop: '_id', sortable: false, cellTemplate: this.butCalTemplate}
    ];
    this.filterChangeObservable.subscribe(async (event) => {
      if (this.userFilter || (event && event.srcElement.value.trim().length)) {
        // fetch the user to get a human-readable user name
        if (this.userFilter) {
          try {
            // check if we're the same user because we don't wanna fetch more than necessary
            if (!this.currentFilter.$and || this.userFilter != this.currentFilter.$and[0].user) {
              const user = await this.userService.get(this.userFilter);
              this.jumpTargetActive = user.name;
            }
          } catch (e) {
            // well this went nowhere, go back to list view
            return this.router.navigate(['../../'], { relativeTo: this.activatedRoute});
          }
        } else {
          this.jumpTargetActive = '';
        }
        // if we have no event then we manually triggered this and use the current filter string
        if (event) {
          this.currentFilterString = event.srcElement.value.trim();
        }
        const currentFilterFromInput = {name: {$search: this.currentFilterString }};
        if (this.userFilter) {
          this.currentFilter = {
            $and: [
              {user: this.userFilter},
              currentFilterFromInput
            ]
          };
        } else {
          this.currentFilter = {
            $or: [
              {user: this.currentFilterString},
              currentFilterFromInput
            ]
          };
        }
      } else {
        this.currentFilter = {};
      }
      await this.setPage({ offset: 0 });
    });

    this.activatedRoute.params.subscribe(async params => {
      if (params.id) {
        this.userFilter = params.id;
        this.filterPlaceholder = 'Type to filter the name column...';
      } else {
        delete this.userFilter;
        this.filterPlaceholder = 'Type to filter the user or name column...';
      }
      this.updateFilter(null);
    });

    this.creatable = this.auth.user.role !== 'admin'; // don't confuse the admin with actions that don't make sense
  }

  openEdit (event, id: String) {
    this.router.navigate(['manager', 'admin', 'lists', id]);
  }

  openCreate (event) {
    this.router.navigate(['manager', 'admin', 'lists', 'create']);
  }

  async deleteEntry (event, id) {
    const confirmation = confirm('Do you want to delete this entry?');
    if (confirmation) {
      this.listService.remove(id);
    }
  }
}
