import { Component, OnInit, ViewChild, TemplateRef, OnDestroy } from '@angular/core';
import { faEdit, faTrash, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from 'src/app/services/auth.service';
import { User, UserService } from 'src/app/services/user.service';
import { MongoDbModel } from 'src/app/services/MongoDbModel';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ListService, List } from 'src/app/services/list.service';
import { SmsService } from 'src/app/services/sms.service';
import { GetByIdPipe } from 'src/app/utils/get-by-id.pipe';
import { SendSmsComponent } from '../send-sms/send-sms.component';


@Component({
  selector: 'app-user-dashboard',
  templateUrl: './user-dashboard.component.html',
  styleUrls: ['./user-dashboard.component.scss']
})
export class UserDashboardComponent implements OnInit, OnDestroy {
  trash = faTrash;
  edit = faEdit;
  send = faPaperPlane;
  @ViewChild('butCol', {static: true}) butCalTemplate: TemplateRef<any>;
  @ViewChild('count', {static: true}) countTemplate: TemplateRef<any>;
  @ViewChild('date', {static: true}) dateTemplate: TemplateRef<any>;
  @ViewChild('blacklistModal', {static: true}) blacklistModal: TemplateRef<any>;
  @ViewChild(SendSmsComponent, {static: true}) smsModal: SendSmsComponent;

  canSendSms = false;
  listColumns = [];
  listData: List[];
  page = {
    pageOffset: 0, // this is the zero based page index
    totalElements: 0,
    limit: 50
  };

  blacklist: string;
  extraNumbers: string;
  smsList: List;
  smsText: string;
  remove: Subscription;
  patch: Subscription;

  constructor(
    public auth: AuthService,
    public userService: UserService,
    public listService: ListService,
    public smsService: SmsService,
    public router: Router,
    public modalService: NgbModal
  ) { }

  async ngOnInit() {

    this.listColumns = [
      {name: 'Name', prop: 'name', sortable: false},
      {name: 'Created', prop: 'createdAt', sortable: false, cellTemplate: this.dateTemplate },
      {name: 'Modified', prop: 'updatedAt', sortable: false, cellTemplate: this.dateTemplate },
      {name: 'Numbers', prop: 'numbers', sortable: false , cellTemplate: this.countTemplate },
      {name: 'Options', prop: '_id', sortable: false, cellTemplate: this.butCalTemplate}
    ];


    this.setPage({offset: 0})

    this.patch = this.listService.patch$.subscribe(this.onChangePatch.bind(this));
    this.remove = this.listService.remove$.subscribe(this.onChangeRemove.bind(this));

    this.canSendSms = this.auth.user.role !== 'admin' && this.auth.user.business && this.auth.user.business.gateway && this.auth.user.business.active;
  }

  async setPage (event) {
    this.page.pageOffset = event.offset;

    const result = await this.listService.find({
      query: {
        $sort: {
          name: 1
        },
        $skip: this.page.pageOffset * this.page.limit,
        $limit: this.page.limit
      }
    });
    this.page.totalElements = result.total;
    this.listData = result.data;
  }

  onChangePatch (el) {
    const index = this.listData.findIndex(entry => entry._id === el.list._id);
    this.listData[index] = el.list;
    this.listData = [...this.listData];
  }

  openEdit (event, id: String) {
    this.router.navigate(['manager', 'admin', 'lists', id]);
  }

  openCreate (event) {
    this.router.navigate(['manager', 'admin', 'lists', 'create']);
  }

  openSend (event, id) {
    this.smsModal.openNew(id);
  }

  editBlacklist() {
    if (this.auth.user.business) {
      this.blacklist = (this.auth.user.business.blacklist || []).join('\n');
    }

    this.modalService.open(this.blacklistModal);
  }

  formatAndSaveBlacklist(modal, blacklist) {
    const blacklistFormatted: string[] = blacklist.
      split(/\n/).
      map(el => el.trim()).
      filter(el => !!el);

    this.auth.user.business.blacklist = blacklistFormatted;

    this.userService.patch(this.auth.user._id, this.auth.user).then(res => modal.close(), err => console.error(err));
  }

  ngOnDestroy() {
    this.modalService.dismissAll();
    if (this.remove) this.remove.unsubscribe();
  }

  onChangeRemove(el) {
    const index = this.listData.findIndex(entry => entry._id === el.list._id);
    // tslint:disable-next-line: no-bitwise
    if (~index) {
      this.listData.splice(index, 1);
      this.listData = [...this.listData]; // change detection
    }
    this.page.totalElements -= 1;
  }

  async deleteEntry (event, id) {
    const confirmation = confirm('Do you want to delete this entry?');
    if (confirmation) {
      this.listService.remove(id);
    }
  }
}
