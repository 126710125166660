import { AfterViewChecked, AfterViewInit, Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { FileData, FileManagerService } from 'src/app/services/file-manager.service';
import { environment } from 'src/environments/environment';
import Dropzone from 'dropzone';
import config from './../../../../../../../mainConfig.json';

@Component({
  selector: 'app-file-upload-resource-form',
  templateUrl: './file-upload-resource-form.component.html',
  styleUrls: ['./file-upload-resource-form.component.scss']
})
export class FileUploadResourceFormComponent implements OnInit, AfterViewInit, AfterViewChecked {
  fileData: FileData = {};
  errorMessage: string;
  fileHost = environment.fileHostUrl;
  dropzoneElement: Element;
  dropzone: any;
  maxFileSizeMb = environment.maxFileSizeMb;
  serverAddress = config.socketAddress;
  params: any;
  renderDropzone = false;
  dropzoneAttatched: boolean;


  constructor(
    public router: Router,
    public fileManagerService: FileManagerService,
    public auth: AuthService,
    public activatedRoute: ActivatedRoute) { }

  async ngOnInit() {
    this.params = this.activatedRoute.snapshot.params;
  }

  async ngAfterViewInit () {
    if (this.params.id && this.params.id !== 'create') {
      this.fileData = await this.fileManagerService.get(this.params.id);
      console.log(this.fileData);
    } else {
      this.fileData = {};
    }
    this.fileData.user = this.auth.user._id;
    this.renderDropzone = true;
  }

  ngAfterViewChecked() {
    if (this.renderDropzone && !this.dropzoneAttatched) {
      this.dropzone = new Dropzone('#dropzone', {
        url: `${this.serverAddress}/file-upload/${this.params.id !== 'create' ? 'patch' : 'create'}`,
        maxFilesize: this.maxFileSizeMb,
        clickable: true,
        thumbnailMethod: 'contain',
        filesizeBase: 1024,
        maxFiles: 1,
        addRemoveLinks: true,
        autoProcessQueue: false,
        timeout: 3600000,
        accept: (file, done) => {
          if (!this.fileData) {
            done('Files cannot be added right now');
          } else {
            done();
          }
        }
      });

      this.dropzone.on('sending', (file, xhr, formData) => {
        // Append all form inputs to the formData Dropzone will POST
        for (const [key, val] of Object.entries(this.fileData)) {
          formData.append(key, val);
        }
      });
      this.dropzone.on('success', (file, response) => {
        console.log('File uploaded', response, file);
        this.router.navigateByUrl('/manager/admin/file-upload');
      });

      this.dropzoneAttatched = true;
    }
  }

  async submitForm (event: Event) {
    if (!this.fileData.name) {
      this.errorMessage = 'Name field can not be empty.';
    } else {
      if (this.params.id === 'create') {
        this.dropzone.processQueue();
      } else {
        await this.fileManagerService.patch(this.params.id, this.fileData);
        this.dropzone.processQueue();
        setTimeout(() => this.router.navigateByUrl('/manager/admin/file-upload'), 2000);
      }
      this.errorMessage = '';
    }
  }

  closeError() {
    this.errorMessage = '';

  }
}
