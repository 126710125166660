import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Feathers } from '../../services/feathers.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  message: string;

  constructor(private feathers: Feathers, private router: Router, private auth: AuthService) {}

  ngOnInit() {
    return this.auth
    .logIn()
    .then(() => this.router.navigate(['/login']), () => true );
  }

  login(email: string, password: string) {
    if (!email || !password) {
      this.message = 'Incomplete credentials!';
      return;
    }

    // try to authenticate with feathers
    this.auth.logIn({
      strategy: 'local',
      email,
      password
    })
      // navigate to base URL on success
      .then(() => {
        if (this.auth.user.role === 'admin') {
          this.router.navigate(['/manager/admin/users']);
        } else {
          this.router.navigate(['/']);
        }
      })
      .catch(err => {
        if (err.name === 'NotAuthenticated') {
          this.message = 'Wrong credentials!';
        } else {
          this.message = 'And error occurred. Please try again later.';
        }
      });
  }
}
